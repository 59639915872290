import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import FirstViewTitle from '@/components/molecules/common/FirstViewTitle';

type FirstViewProps = {
  children: React.ReactNode;
  titleEn: string;
  title: string;
  titlePosition: 'left' | 'right';
};

const firstViewBox = (theme: Theme) => css({
  height: '25vh',
  position: 'relative',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});

const FirstView: React.VFC<FirstViewProps> = (props) => {
  const { children, titleEn, title, titlePosition } = props;

  return (
    <section>
      <Box css={firstViewBox}>
        {children}
        <FirstViewTitle
          titleEn={titleEn}
          title={title}
          titlePosition={titlePosition}
        />
      </Box>
    </section>
  );
};

export default FirstView;
