import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type FirstViewTitleProps = {
  titleEn: string;
  title: string;
  titlePosition: 'left' | 'right';
};

const firstViewTitleBox = css({
  position: 'absolute',
  width: '90%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const firstViewMainTitle = css({
  fontSize: '2rem',
  fontWeight: 600,
  letterSpacing: '.2rem'
});

const firstViewSubTitle = css({
  color: '#ffffff',
  fontSize: '1.2rem',
  letterSpacing: '.1rem',
});

const FirstViewTitle: React.VFC<FirstViewTitleProps> = (props) => {
  const { titleEn, title, titlePosition } = props;

  return (
    <Box css={firstViewTitleBox}>
      <Typography
        variant="h1"
        css={firstViewMainTitle}
        align={titlePosition}
      >
        {titleEn}
      </Typography>
      <Typography
        variant="subtitle1"
        css={firstViewSubTitle}
        align={titlePosition}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default FirstViewTitle;
