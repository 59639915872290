import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import HistoryBlock from '@/components/organisms/history/HistoryBlock';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});
const HistoryPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="社史"
        description="60年以上の歴史を持つ、株式会社 富士見工務店の歩みをご紹介します。"
      />
      <FirstView
        titleEn="History"
        title="社史"
        titlePosition="left"
      >
        <StaticImage
          alt="HistoryImage"
          src="../images/firstviews/history.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 60%"
        />
      </FirstView>
      <HistoryBlock />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default HistoryPage;
